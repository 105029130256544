<template>
  <div id="FlightDelayReport" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Flight (req. by flt ops)</div>
      </div>
    </div>

    <div id="filltertReport" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Filter</div>
      </div>

      <div class="box-S3">
        <div class="B-carddetail flex-between-center">
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="listyear"
              v-model="picker"
              label="Year"
              return-object
              @change="RenderExcelFlightReport()"
            ></v-select>
          </div>
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="listMonth"
              v-model="month"
              item-text="value"
              item-value="key"
              label="Month"
              return-object
              @change="RenderExcelFlightReport()"
            ></v-select>
          </div>
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListCustomer"
              v-model="selectowner"
              item-text="o_name"
              item-value="o_id"
              label="Customer"
              return-object
              @change="RenderExcelFlightReport()"
            ></v-select>
          </div>
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListDeparture"
              v-model="selectDeparture"
              item-text="ap_name"
              item-value="ap_id"
              label="Departure From"
              return-object
              @change="departureFromChange()"
            ></v-select>
          </div>
        </div>
      </div>

      <div class="box-S4">
        <ejs-grid
          ref="gridReport1"
          id="GridReport1"
          :dataSource="report"
          :allowGrouping="false"
          :toolbar="toolbarOptions"
          :allowExcelExport="true"
          :toolbarClick="toolbarClickReport1"
          :allowPaging="false"
          :allowSorting="true"
          :allowMultiSorting="true"
          :allowTextWrap="true"
          :textWrapSettings="wrapSettings"
          width="84%"
        >
          <e-columns>
            <e-column
              field="Pax_No"
              headerText="No."
              headerTextAlign="Center"
              textAlign="Center"
              width="100"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="year"
              headerText="Year"
              headerTextAlign="Center"
              textAlign="Center"
              width="110"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="month"
              headerText="Month"
              headerTextAlign="Center"
              textAlign="Center"
              width="110"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="date"
              headerText="Date"
              headerTextAlign="Center"
              textAlign="Center"
              width="100"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Flight_Type"
              headerText="Flight Type (e.g. Normal, Medevac, Train, Test)"
              headerTextAlign="Center"
              textAlign="Center"
              width="200"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Aircraft_registration"
              headerText="Aircraft<br/>registration"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Pilot_in_command"
              headerText="Pilot in command"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="First_officer"
              headerText="First officer"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Customer"
              headerText="Customer"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Route"
              headerText="Route"
              headerTextAlign="Center"
              textAlign="Center"
              width="200"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="std"
              headerText="STD<br/>(HH:MM)"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Start"
              headerText="Start<br/>(HH:MM)"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="taxi"
              headerText="Taxi<br/>(HH:MM)"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Take_off"
              headerText="Take off (HH:MM)"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Delay"
              headerText="Delay<br/>(HH:MM)"
              headerTextAlign="Center"
              textAlign="Center"
              width="150"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Cause_of_delay"
              headerText="Cause of delay"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="airtime"
              headerText="Air time takeoff - land (HH:MM)"
              headerTextAlign="Center"
              textAlign="Center"
              width="220"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="blocktime"
              headerText="Flight time start-stop (HH:MM)"
              headerTextAlign="Center"
              textAlign="Center"
              width="220"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Outbound_passenger_no_book"
              headerText="Outbound passenger no. (booking)"
              headerTextAlign="Center"
              textAlign="Center"
              width="220"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Outbound_passenger_no_act"
              headerText="Outbound passenger no. (actual)"
              headerTextAlign="Center"
              textAlign="Center"
              width="220"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Offload_reason"
              headerText="Offload reason"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Outbound_payload_available"
              headerText="Outbound payload (available)"
              headerTextAlign="Center"
              textAlign="Center"
              width="200"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Outbound_payload_book"
              headerText="Outbound payload (booking)"
              headerTextAlign="Center"
              textAlign="Center"
              width="200"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Outbound_payload_act"
              headerText="Outbound payload (actual)"
              headerTextAlign="Center"
              textAlign="Center"
              width="200"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Inbound_passenger_no_book"
              headerText="Inbound passenger no. (booking)"
              headerTextAlign="Center"
              textAlign="Center"
              width="200"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Inbound_no_act"
              headerText="Inbound passenger no. (actual)"
              headerTextAlign="Center"
              textAlign="Center"
              width="200"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Inbound_payload_available"
              headerText="Inbound payload (available)"
              headerTextAlign="Center"
              textAlign="Center"
              width="200"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Inbound_payload_book"
              headerText="Inbound payload (booking)"
              headerTextAlign="Center"
              textAlign="Center"
              width="200"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Inbound_payload_act"
              headerText="Inbound payload (actual)"
              headerTextAlign="Center"
              textAlign="Center"
              width="180"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Milage"
              headerText="Milage (NM)"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
            <e-column
              field="Fuel"
              headerText="Fuel (Ltrs.)"
              headerTextAlign="Center"
              textAlign="Center"
              width="140"
              :disableHtmlEncode="false"
            ></e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  lastDayOfYear,
  format,
  getDate,
} from "date-fns";
import MonthlyReport from "../utils/MonthlyReport";
import moment from "moment";
//Grid จำเป็น
import {
  GridPlugin,
  Page,
  Sort,
  Group,
  Toolbar,
  ExcelExport,
  Resize,
} from "@syncfusion/ej2-vue-grids";

export default {
  data: () => ({
    items: [],
    total: 0,
    loading: false,
    dialog: false,
    formModel: {},
    type: "month",
    picker: 0,
    dateSelect: new Date().toISOString().substr(0, 4),
    menu: false,
    // itemsListCompany: [],
    //Edit
    itemsListCustomer: [],
    // selectcompany:"",
    selectowner: "",
    //Add
    userdata: null,
    listyear: [],
    listMonth: [],
    month: null,
    itemsListDeparture: [],
    selectDeparture: {},

    report: null,
    toolbarOptions: ["ExcelExport"],

    wrapSettings: { wrapMode: "Both" },
  }),
  computed: {},
  async mounted() {
    //Add
    await this.renderListDeparture();
    this.userdata = this.$CurrentUser.GetCurrentUser();

    // this.SelectListCompany();
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    this.listyear = range(currentYear, currentYear - 10, -1);
    this.picker = new Date().getFullYear();

    var month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    for (let i = 1; i <= 12; i++) {
      let obj = { key: i, value: month[i - 1] };
      this.listMonth.push(obj);
    }
    this.month = this.listMonth[new Date().getMonth()];

    await this.SelectListOwner();

    await this.RenderExcelFlightReport();
  },

  provide: {
    grid: [Page, Sort, Group, Toolbar, ExcelExport, Resize],
  },

  methods: {
    async RenderExcelFlightReport() {
      try {
        const q = {};
        q.month = this.month.key;
        q.year = this.picker;
        q.o_id = this.selectowner.o_id;
        q.ap_id = this.selectDeparture.ap_id;

        var res = await feathersClientUOA
          .service("flight-report-1")
          .find({ query: q });
        this.report = res;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    toolbarClickReport1(args) {
      if (args.item.id === "GridReport1_excelexport") {
        this.$refs.gridReport1.excelExport();
      }
    },
    async SelectListOwner() {
      try {
        const q = {};
        //Add

        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListCustomer = res.data;
        this.selectowner = {
          o_id: res.data[0].o_id,
          o_id: res.data[0].o_id,
        };
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async renderListDeparture() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
      await this.selectDepartureFrom();
    },
    async selectDepartureFrom() {
      try {
        let departureChange =
          await this.$LocalStorageService.getDepartureChange();
        if (departureChange == null) {
          this.selectDeparture = {
            ap_id: this.itemsListDeparture[0].ap_id,
            ap_name: this.itemsListDeparture[0].ap_name,
          };
        } else {
          let obj = JSON.parse(departureChange);
          this.selectDeparture = { ap_id: obj.ap_id, ap_name: obj.ap_name };
        }
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },
    async departureFromChange(arg) {
      this.$LocalStorageService.setDepartureChange(
        this.selectDeparture.ap_id,
        this.selectDeparture.ap_name
      );
      this.RenderExcelFlightReport();
    },
  },
};
</script> 


<style>
</style>